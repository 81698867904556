import store from '../store'
import router from '@/router'

// Headers that are the same for most requests.
const standardHeaders = {
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'omit',
  redirect: 'follow',
  referrer: 'no-referrer'
}

function authHeader () {
  // return authorization header with jwt token
  const { authObject } = store.state

  if (authObject && authObject.user.token) {
    return {
      'Content-type': 'application/json',
      Authorization: `Bearer ${authObject.user.token}`
    }
  }
  return {
    'Content-type': 'application/json'
  }
}

function authPostHeader () {
  // return authorization header with jwt token
  const { user } = store.state

  if (user && user.token) {
    return {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${user.token}`
    }
  }
  return {
    'Content-Type': 'multipart/form-data'
  }
}

// const flaskServer = `${window.location.protocol}//${window.location.host}/api`
const flaskServer = process.env.VUE_APP_FLASK_SERVER

export default {
  /**
   * fetch wrapper for GET requests to api
   *
   * @param {string} url
   * @param {object} queryparams
   * @returns {Promise} raw response
   */
  async get (url, queryparams) {
    // Construct URL with query parameters
    const newUrl = new URL(
      `${flaskServer}${url}`
    )
    Object.keys(queryparams || []).forEach(
      (key) => newUrl.searchParams.append(key, queryparams[key])
    )

    return fetch(newUrl, {
      ...standardHeaders,
      method: 'GET',
      headers: authHeader()
    }).then((response) => {
      if (response.status >= 400) {
        // throw Error(`${response.status}: ${response.statusText}`)
        console.log(`${response.status}: ${response.statusText}`)
        store.dispatch('logOut')
        router.push('/login')
        return null
        // throw new Error(`${response.status}: ${response.statusText}`)
      } else {
        return response.json()
      }
    })
  },

  // Example POST method implementation:
  async postData (url, data) {
    const newUrl = new URL(
      `${flaskServer}${url}`
    )
    // Default options are marked with *
    const response = await fetch(newUrl, {
      ...standardHeaders,
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: authHeader(),
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    if (response.status >= 400) {
      // throw `${resp.status}: ${resp.statusText}`;
      console.log(`${response.status}: ${response.statusText}`)
    }
    return response // parses JSON response into native JavaScript objects
  },

  async postFile (url, formData) {
    const newUrl = new URL(
      `${flaskServer}${url}`
    )
    // Default options are marked with *
    const response = await fetch(newUrl, {
      ...standardHeaders,
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: authPostHeader(),
      body: formData // body data type must match "Content-Type" header
    })
    if (response.status >= 400) {
      // throw `${resp.status}: ${resp.statusText}`;
      console.log(`${response.status}: ${response.statusText}`)
    }
    return response // parses JSON response into native JavaScript objects
  }
  //
  // async patch(url, payload) {
  //     return jsonRequest(url, "PATCH", payload);
  // },
  //
  // /**
  //  * Make an api post.
  //  *
  //  * @param {string} url
  //  * @param {object} payload
  //  * @return {Promise} json parsed response
  //  */
  // async post(url, payload) {
  //     // fetch options: https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
  //     return jsonRequest(url, "POST", payload).then(async resp => {
  //         try {
  //             const json = await resp.json();
  //             return json;
  //         } catch (err) {
  //             console.error(err);
  //             if (!resp.ok) {
  //                 throw `${resp.status}: ${resp.statusText}`;
  //             }
  //
  //             throw err;
  //         }
  //     });
  // },

}
